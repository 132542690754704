@use '../includes' as *;

$logo-width: 75px;

.sponsorship_logos_wrapper {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  column-gap: $g16;
  row-gap: $g4;
}

.sponsorship_logos_intro {
  color: $pebble;
  font-size: $f12;
  font-style: italic;
  font-weight: $normal;
  white-space: nowrap;

  @include breakpoint($sm) {
    @include h5;

    color: $pebble;
    font-size: $f12;
    font-style: normal;
    font-weight: $bold;
  }
}

.sponsor_logos {
  display: flex;
  flex-wrap: nowrap;
  gap: $g8;
}

.sponsor_link {
  display: flex;
  align-items: center;
  aspect-ratio: 18 / 7;
}

.sponsor_logo {
  width: $logo-width;
  max-inline-size: none;
  opacity: 0.7;
}
