@use '../includes' as *;
@use './VideoPlayer.module.scss' as vp;
@use 'sass:color';

.passport_benefit_screen {
  position: relative;
  z-index: 4;
  padding-block: 33% $g20;
  padding-inline: var(--spacing-inline);
  border-block-end: $slate-border;
  pointer-events: auto;

  @include containerminwidth(vp.$smbp) {
    position: absolute;
    inset: auto 0 $g20 0;
  }

  @include containerminwidth(vp.$mdbp) {
    display: flex;
    inset: 14cqw auto auto 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding-block: $g20;
    padding-inline: $g60;
    transform: translate(-50%, 0);
    border-block-end: none;
    border-radius: 8px;
    background: rgb($midnight, 0.6);
    backdrop-filter: blur(15px);
  }

  @include containerminwidth(vp.$lgbp) {
    inset: 27cqw auto auto 50%;
    transform: translate(-50%, -50%);
  }
}

.passport_benefit_headline {
  @include h3;

  margin-block-end: $g8;
}

.passport_benefit_headline_intro {
  margin-inline-end: 0.3em;
}

.passport_benefit_station_lockup {
  display: inline-flex;
  align-items: center;
  gap: $g4;
}

.passport_benefit_screen_passport_compass {
  width: 16px;
  height: 16px;
}

.passport_benefit_explanation {
  @include vp.video-player-body-copy;

  max-width: 500px;
  margin-block-end: $g8;

  @include containerminwidth(vp.$smbp) {
    margin-block-end: $g12;
  }

  @include containerminwidth(vp.$mdbp) {
    text-align: center;
  }

  @include containerminwidth(vp.$mdlgbp) {
    max-width: 600px;
  }
}

a.passport_benefit_plm_link_small {
  display: block;
  margin-block-end: $g12;
  color: $yellow;
  font-size: $f12;
  font-weight: $bold;
  line-height: 1.5;

  @include containerminwidth(vp.$smbp) {
    display: none;
  }
}

.passport_benefit_screen_ctas {
  display: flex;
  align-items: center;
  gap: $g12;
  margin-block-end: $g12;
}

a.passport_benefit_plm_link_lg {
  display: none;

  @include containerminwidth(vp.$smbp) {
    display: flex;
  }
}

.passport_benefit_screen_support {
  font-size: $f12;
  line-height: 1.5;

  button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  button,
  a {
    display: inline;
    margin-inline-start: $g4;
    color: currentcolor;
    text-decoration: underline;

    @include containerminwidth(vp.$smbp) {
      transition: color var(--duration-half) ease-in;
      color: $yellow;
      font-weight: $bold;
      text-decoration: none;

      &:hover,
      &:focus {
        color: color.mix($midnight, $yellow, 20%);
      }
    }
  }
}
