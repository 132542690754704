@use '../includes' as *;

$video-hero-bp: $sm;

@mixin videohero() {
  position: relative;
  // keeps player from getting _too_ small relative to the viewport
  min-width: 33vw;
  aspect-ratio: 16 / 9;
  background-color: $midnight;

  @include breakpoint($video-hero-bp) {
    padding-inline: unset;
  }
}

.video_hero_loaded {
  @include videohero;

  // this keeps the player from being so tall that the controls aren't visible
  max-height: calc(100vh - var(--navigation-height));
  margin: 0 auto var(--spacing-block) auto;
  padding-inline: var(--spacing-inline);
}

.video_hero_initial {
  @include videohero;

  width: 100%;
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);

  @include breakpoint($video-hero-bp) {
    margin-block-end: calc(var(--spacing-block) * -1);
  }

  @include breakpoint($md) {
    margin-block-end: calc((var(--spacing-block) + 5rem) * -1);
  }

  @include breakpoint($lg) {
    margin-block-end: calc((var(--spacing-block) + 8rem) * -1);
  }

  @include breakpoint($xl) {
    margin-block-end: calc((var(--spacing-block) + 12rem) * -1);
  }

  @include breakpoint($xxl) {
    margin-block-end: calc((var(--spacing-block) + 15rem) * -1);
  }
}

.video_hero_video_initializer {
  button {
    inset-block-start: 0;

    @include breakpoint($video-hero-bp) {
      inset-block-start: -10%;
    }
  }

  @include breakpoint($below-sm) {
    overflow: hidden;
    border-radius: $radius-large $radius-large 0 0;
  }
}

.video_hero_initial_overlay {
  display: flex;
  position: relative;
  inset: 0;
  flex-direction: column;
  justify-content: center;
  padding-block: 20px;
  padding-inline: var(--spacing-inline);
  border-radius: 0 0 $radius-large $radius-large;
  background-color: $navy-blue;
  pointer-events: none;

  button,
  a {
    pointer-events: auto;
  }

  @include breakpoint($video-hero-bp) {
    position: absolute;
    // Accounting for a small 1px gap between background and gradient
    inset-block-end: -1px;
    border-radius: unset;
    background-color: transparent;
    background-image: linear-gradient(
        to top,
        $midnight 5%,
        rgba($midnight, 0.36) 22%,
        rgba($midnight, 0) 38%
      ),
      linear-gradient(
        to right,
        $midnight 3%,
        rgba($midnight, 0.36) 30%,
        rgba($midnight, 0) 50%
      );
  }
}

.video_show_logo {
  width: clamp(140px, 20%, 240px);
  margin-block-end: $g16;
}

.video_description {
  @include body-copy-3;

  @include breakpoint($video-hero-bp) {
    width: 30%;

    @include body-copy-1;

    font-size: clamp($f14, 1.1714rem + 0.2976vw, $f16);
  }

  margin-block-end: var(--hero-gutter);
}

.video_passport_callout {
  display: flex;
  margin-block-end: var(--hero-gutter);
  color: $pebble;
  font-size: $f12;

  @include breakpoint($video-hero-bp) {
    color: $fog;
  }
}

.compass_rose_icon {
  height: 1.5em;
  padding-inline-end: $g4;
}

.video_show_overtitle {
  @include h1;

  margin-block-end: $g12;
}

.video_title {
  @include h2;

  margin-block-end: $g8;
  line-height: 1.5;

  @include breakpoint($xs-only) {
    margin-block-end: $g12;
  }

  @include breakpoint($video-hero-bp) {
    width: 35%;
    margin-block-end: $g16;
  }
}

.video_hero_initial_overlay_buttons {
  display: flex;
  position: relative;
  gap: $g16;

  button,
  a {
    pointer-events: auto;
  }
}

.video_hero_explore_the_show_button {
  display: none;

  @include breakpoint($video-hero-bp) {
    display: block;
  }
}

li.video_hero_kabob_item_explore_the_show {
  display: block;

  @include breakpoint($video-hero-bp) {
    display: none;
  }
}

li.video_hero_kabob_item_link {
  svg {
    height: 0.7em;
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      path {
        fill: $pebble;
      }
    }
  }
}

.video_hero_loaded_overlay {
  display: flex;
  position: relative;
  inset: 0;
  z-index: 4;
  flex-direction: column;
  justify-content: center;
  padding-block: 20px;
  padding-inline: var(--spacing-inline);
  background-color: $navy-blue;
  pointer-events: none;

  @include breakpoint($video-hero-bp) {
    display: block;
    position: absolute;
    inset: unset;
    // magic numbers
    inset-block-end: 19px;
    inset-inline-start: $g32;
    width: calc(100% - 36px);
    padding: unset;
    border-radius: unset;
    background-color: transparent;

    .video_description {
      display: none;
    }
  }

  .video_show_logo {
    width: clamp(140px, 20%, 200px);
  }

  @include breakpoint($mdlg) {
    inset-block-end: $g20;
    inset-inline-start: $g64;
  }
}

.video_hero_loaded_video_player {
  overflow: hidden;
  border-radius: $radius-large $radius-large 0 0;

  @include breakpoint($video-hero-bp) {
    overflow: visible;
    border-radius: unset;
  }
}

.video_hero_loaded_overlay_buttons {
  display: flex;
  gap: $g16;

  button,
  a {
    pointer-events: auto;
  }
}

.video_hero_kabob_button_above_sm,
.video_hero_kabob_content_above_sm {
  display: none;

  @include breakpoint($video-hero-bp) {
    display: block;
  }
}

.video_hero_kabob_content_below_sm {
  display: block;

  @include breakpoint($video-hero-bp) {
    display: none;
  }
}

button.video_hero_kabob_button_below_sm {
  position: absolute;
  inset-block-start: $g8;
  inset-inline-end: calc(var(--spacing-inline) + 10px);
  margin-inline-end: -16px;
  padding: unset;
  border: 0;
  pointer-events: auto;

  @include breakpoint($video-hero-bp) {
    display: none;
  }
}
